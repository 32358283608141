<template>
  <listado/>
</template>
<script>
import listado from './components/listadoCumplimiento.vue'

export default {
  name: "CMProcesoAprobacionCompraElectronicaListado",
  components: {
    listado,
  },
}
</script>
